import { css } from 'styled-components'
import globalSizes from './sizes'

const sizes = {
  tablet: globalSizes.TABLE_MAX_WIDTH,
  mobile: globalSizes.MOBILE_MAX_WIDTH,
}

const desktop = (...args) => css`
  @media (min-width: ${sizes.tablet + 1}px) {
    ${css(...args)};
  }
`

export default Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
     @media (max-width: ${sizes[label]}px) {
        ${css(...args)};
     }
  `
  return acc
}, { desktop })
