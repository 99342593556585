import styled from 'styled-components'
import { media } from 'styles'

const LAYOUT_FIXED_WIDTH = 1600;

export default styled.div`
  margin: 0 auto;
  width: ${LAYOUT_FIXED_WIDTH}px;
  max-width: 100%;

  ${media.tablet`
    padding: 0 30px;
  `}

  ${media.mobile`
    padding: 0 9px;
  `}
`
