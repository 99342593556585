export default {
  PRIMARY: '#071246',
  SECONDARY: '#FFBD00',
  BORDER: '#D9E2E6',
  DARK_GREY: '#82878A',
  GREY: '#ADB4B8',
  ERROR: '#cc180c',
  WHITE: '#ffffff',
  SMOKE: '#F0F0F0',
  BLACK: '#000000'
}
