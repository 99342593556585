import styled, { createGlobalStyle } from 'styled-components'
import Layout from '../Layout'
import { colors, fonts, media, sizes } from 'styles'

export const GlobalStyle = createGlobalStyle`
  body {
    font-family: ${fonts.PRIMARY};
    font-size: 18px;

    ${media.mobile`
      font-size: 16px;
    `}
  }

  h2, h6 {
    color: ${colors.PRIMARY};
    font-family: ${fonts.SECONDARY};
    margin: 0;
  }

  h2 {
    font-size: 48px;
    font-weight: normal;
    line-height: 1.3em;
    letter-spacing: -.01em;

    ${media.tablet`
      font-size: 40px;
    `}
    ${media.mobile`
      font-size: 32px;
    `}
  }

  h6 {
    font-size: 16px;
    font-weight: 900;
    line-height: 1.375em;
    letter-spacing: .05em;
  }
`

export const Wrapper = styled.div`
  ${media.desktop`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  `}

  ${media.tablet`
    overflow-x: hidden;
  `}
`

export const Main = styled(Layout)`
  ${media.desktop`
    display: flex;
    flex: auto;
    ${props => props.restrictHeight &&`
      max-height: 808px;
    `}
  `}
`

export const Sidebar = styled.div`
  width: ${sizes.SIDEBAR_WIDTH}px;
  flex: none;
  border-right: 1px solid ${colors.BORDER};

  ${media.tablet`
    display: none;
  `}
`

export const LogoWrapper = styled.div`
  width: 97px;
  margin: 24px auto 0;

  img {
    width: 100%;
    height: auto;
  }
`

export const Content = styled.div`
  flex: auto;
`

export const Footer = styled.div`
  flex: none;
  height: ${sizes.FOOTER_HEIGHT}px;
  border-top: 1px solid ${colors.BORDER};

  ${media.tablet`
    height: auto;
    padding-bottom: 100px;
  `}
`

export const FooterLayout = styled(Layout)`
  padding-left: ${sizes.SIDEBAR_WIDTH}px;
  padding-top: 45px;

  ${media.tablet`
    padding: 45px 0;
    text-align: center;
  `}
`

export const Copyright = styled.div`
  font-size: 14px;
  line-height: 30px;
  display: inline-block;
`

export const Headerbar = styled.div`
  display: none;

  ${media.tablet`
    display: block;
  `}
`
