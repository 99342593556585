import React from 'react';
import { SiteTitleQuery } from 'graph';
import { withPrefix } from 'gatsby';

const Image = () => {
  const title = SiteTitleQuery();
  return (
    <img src={withPrefix('logo.png')} alt={title} />
  );
};

export default Image
