import { useStaticQuery, graphql } from 'gatsby';

export const SiteTitleQuery = () => {
  const { site: { siteMetadata: { title } } } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  return title;
};
