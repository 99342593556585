/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import LogoImage from '../Images/LogoImage';
import 'normalize.css/normalize.css';
import 'fonts/Fontello/stylesheet.css';
import 'fonts/Avenir/stylesheet.css';
import {
  GlobalStyle,
  Wrapper,
  Main,
  Headerbar,
  Sidebar,
  LogoWrapper,
  Content,
  Footer,
  FooterLayout,
  Copyright
} from './styles';

const PageLayout = ({ hideFooter, restrictHeight, children }) => (
  <Wrapper>
    <GlobalStyle />
    <Main restrictHeight={restrictHeight}>
      <Headerbar />
      <Sidebar>
        <LogoWrapper>
          <Link to="/">
            <LogoImage />
          </Link>
        </LogoWrapper>
      </Sidebar>
      <Content>
        {children}
      </Content>
    </Main>
    {!hideFooter && (
      <Footer>
        <FooterLayout>
          <Copyright>{`© ${(new Date()).getFullYear()} Brill Place Tower`}</Copyright>
        </FooterLayout>
      </Footer>
    )}
  </Wrapper>
);

PageLayout.propTypes = {
  hideFooter: PropTypes.bool,
  restrictHeight: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

PageLayout.defaultProps = {
  restrictHeight: true
}

export default PageLayout
